@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  75% {
    transform: scale(3);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    transform: translate(75%, 0) scale(0.75);
  }
  100% {
    transform: translate(125%, 0) scale(0.75);
  }
}

@keyframes bounce-alt {
  0% {
    transform: scale(0.75);
    margin: 0 0 -3.5px 6px;
  }
  100% {
    transform: scale(0.75);
    margin: 0 0 -3.5px 10px;
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * @license
 * MyFonts Webfont Build ID 3614215, 2018-07-23T16:39:39-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FrutigerLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/frutiger-pro-45-light/
 * 
 * Webfont: FrutigerLTPro-LightCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-47-light-condensed/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3614215
 * Licensed pageviews: 15
 * Webfonts copyright: Copyright &#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/

@font-face {
  font-family: "FrutigerLTPro-Light";
  src: url("webfonts/372607_0_0.eot");
  src: url("webfonts/372607_0_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/372607_0_0.woff2") format("woff2"),
    url("webfonts/372607_0_0.woff") format("woff"),
    url("webfonts/372607_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "FrutigerLTPro-LightCn";
  src: url("webfonts/372607_1_0.eot");
  src: url("webfonts/372607_1_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/372607_1_0.woff2") format("woff2"),
    url("webfonts/372607_1_0.woff") format("woff"),
    url("webfonts/372607_1_0.ttf") format("truetype");
}

/* Global */
* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100vh;
  margin: 0;
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  font-family: "FrutigerLTPro-Light", Courier, "Lucida Grande",
    "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
  font-size: 14px;
  line-height: 1.4em;
  background: #ededed;
  color: #000;
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Phone Landscape ( + ) */
@media only screen and (min-width: 480px) {
  body {
    font-size: 16px;
  }
}

/* Tablet Portrait ( + ) */
@media only screen and (min-width: 768px) {
  body {
    font-size: 18px;
  }
}

/* Tablet Landscape ( + ) */
@media only screen and (min-width: 992px) {
  body {
    font-size: 20px;
  }
}

/* Laptop ( + ) */
@media only screen and (min-width: 1280px) and (min-height: 700px) {
  body {
    font-size: 22px;
  }
}

/* Monitor ( + ) */
@media only screen and (min-width: 1440px) and (min-height: 700px) {
  body {
    font-size: 24px;
  }
}

a {
  font-family: "FrutigerLTPro-LightCn", Courier, "Lucida Grande",
    "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
  text-decoration: none;
  color: #000;
}

p {
  display: inline-block;
}

h1 {
  font-size: 1.8em;
}

button {
  border: none;
  outline: none;
  font-size: 24px;
  padding: 0;
  line-height: 1.4em;
  font-family: "FrutigerLTPro-LightCn", Courier, "Lucida Grande",
    "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

#root {
  display: flex;
}

.screen {
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border: 24px solid #ededed;
  padding: 24px;
  background: white;
}

.screen_content {
  width: 100%;
  margin: 0 auto;
  display: none;
  height: 100%;
  position: relative;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
}

.content_wrapper {
  display: inline-block;
  width: 100%;
  flex-grow: 2;
  position: relative;
  /*  max-width: 1024px;*/
}

.content_wrapper--landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 30px;
}

.ribbon_wrapper {
  position: absolute;
  bottom: -54px;
  right: 0;
  width: 280px;
  height: 275px;
  overflow: hidden;
  display: none;
}

/* Global Header */

.global_header {
  margin: 0 0 24px;
  z-index: 100;
  position: relative;
  width: 100%;
}

.global_header:after {
  content: "";
  display: table;
  clear: both;
}

/* Header */

.header_wrapper {
  z-index: 50;
  position: relative;
}

.header {
  font-size: 1.8em;
  line-height: 1.25em;
  padding-bottom: 0.25em;
  text-shadow: 0px 1px 0.5em white;
}

/* Graph */
/*
.graph_note {
	display: none;
}*/

.graph_notes {
  display: inline-block;
  height: 3.25em;
  position: absolute;
  bottom: 10vw;
  /*  margin-top: -4em;*/
  left: 0;
}

.graph_content {
  position: absolute;
  width: 100%;
  bottom: 10px;
  left: 0;
  /*	max-width: 1024px;
*/
}

/* Quiz Headline */

.quiz_headline_wrapper {
  margin-bottom: 24px;
}

.quiz_headline {
  padding-bottom: 0;
}

.main_header {
  font-size: 4em;
  line-height: 1.2em;
  /* font-size: 1.8em;
  line-height: 1em; */
}

.main_subheader {
  font-size: 2.4em;
  line-height: 1.2em;
  margin-bottom: 0.5em;
}

.red {
  color: #90433b;
}

/* Advance Button */

.advance_button_wrapper {
  padding: 0;
  align-self: flex-end;
  width: 100%;
  z-index: 100;
  position: relative;
  min-height: 35px;
}

.disclaimer {
  display: inline-block;
  width: 40%;
}

.advance_button_content_wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 60%;
  display: inline-block;
}

.result_buttons_wrapper {
  display: flex;
  justify-content: space-around;
}

.result_buttons_wrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-grow: 8;
}

.contact_buttons_wrapper {
  margin: 0.75em 0 1em;
  /*	justify-content: flex-start;*/
  width: 100%;
  flex-grow: 0;
}

.landing_buttons_wrapper {
  display: flex;
  justify-content: flex-start;
  margin: 16px 0;
}

.start_button_wrapper {
  display: flex;
  margin-right: 120px;
}

.result_button_wrapper {
  width: 40%;
  max-width: 275px;
  margin: 20px auto;
  display: inline-block;
  /*	flex-direction: column;
	justify-content: flex-start;*/
}

.contact_button_wrapper {
  width: 35%;
  color: #000;
}

.contact_button {
  border: 1px solid #ccc;
  padding: 0.75em 1em;
  margin: 0 1.25em 0 0;
  color: #000;
  font-size: 1em;
  line-height: 1.4em;
}

.contact_button .button_copy {
  font-family: "FrutigerLTPro-LightCn", Courier, "Lucida Grande",
    "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
}

.result_button {
  color: #777;
  font-size: 20px;
  line-height: 1.4em;
}

/*.result_button p {
	font-size: 1em;
}*/

.start_button {
  background: #8aa75d;
  color: #fff;
  text-align: center;
  padding: 1em 0.9em 1em 1.1em;
  line-height: 1em;
  font-size: 1em;
}

.advance_button_wrapper:after {
  content: "";
  display: table;
  clear: both;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 1.5em solid transparent;
  border-bottom: 1.5em solid transparent;
  border-left: 1.5em solid #8aa75d;
}

/* Body Copy */

.body_copy_wrapper {
  padding: 0;
  max-width: 74%;
  z-index: 50;
  position: relative;
}

.body_copy {
  font-weight: 400;
  padding-bottom: 1em;
  text-shadow: 0px 1px 0.5em white;
  color: #4d4d4d;
}

.body_copy:last-child {
  padding-bottom: 0;
}

.legal_copy_wrapper {
  display: inline-block;
  width: 100%;
  flex-grow: 1;
  min-height: 60vh;
  overflow-y: scroll;
}

.legal_copy_wrapper p.option_copy {
  font-size: 1em;
  margin: 0 0 0.75em;
  color: black;
  line-height: 1.4em;
  padding: 0;
}

@media only screen and (min-width: 480px) {
  .legal_copy_wrapper p.option_copy {
    font-size: 0.6em;
  }
}

@media only screen and (min-width: 768px) {
  .legal_copy_wrapper p.option_copy {
    font-size: 0.7em;
  }
}

@media only screen and (min-width: 992px) {
  .legal_copy_wrapper p.option_copy {
    font-size: 0.8em;
  }
}

@media only screen and (min-width: 1280px) and (min-height: 700px) {
  .legal_copy_wrapper p.option_copy {
    font-size: 0.9em;
  }
}

.landing_copy_wrapper {
  /*max-width: 80%; */
  font-size: 1.2em;
  line-height: 1.4em;
  /* font-size: 20px;
  line-height: 1.4em; */
}

.back_button {
  width: 100%;
  background: #8aa75d;
  text-align: center;
  margin: 24px 0 0;
  color: #fff;
  padding: 8px 0;
}

.contact_disclaimer {
  display: inline-block;
  width: 100%;
  flex-grow: 8;
}

.contact_disclaimer_copy {
  margin-bottom: 1em;
}

/* Options */

.options {
  display: flex;
  padding: 0;
  color: #6d6e71;
  font-size: 16px;
  line-height: 1.5em;
  flex-wrap: wrap;
  justify-content: space-around;
}

.option {
  flex-grow: 1;
  min-width: 100px;
  width: 25%;
  padding: 8px;
  transition: all 0.3s ease;
}

.option_copy {
  margin-bottom: 16px;
}

.option_image {
  margin-bottom: 16px;
}

.option--button:hover {
  cursor: pointer;
  background: #ededed;
}

.option--completed {
  background-color: green;
}

.button_image {
  display: block;
  margin: 0 auto 24px;
}

.button_copy {
  display: block;
}

/* Graphs */

.backdrop {
  position: absolute;
  background: red;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.graph_wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 12vw 16px;
  position: relative;
  justify-content: flex-end;
}

.time-scale {
  display: inline-block;
  margin: 0 auto;
  padding: 0 12vw;
  width: 100%;
  /*	font-size: 0.82em;
	line-height: 1.4em;*/
}

.time-scale__value {
  display: inline-block;
  border-top: 0.19vw solid #111;
  padding-top: 8px;
  height: 50px;
  text-align: left;
}

.time-scale__value--0-5,
.time-scale__value--5-10 {
  width: 16.6666665%;
}

.time-scale__value--10-20,
.time-scale__value--20-30 {
  width: 33.333333%;
}

.time-scale__marker {
  display: inline-block;
}

.time-scale__marker--20 {
  transform: translateX(-50%);
}

.time-scale__marker--30 {
  float: right;
}

/*.graph_note {
	color: #777;
	padding: 20px 20px 20px 0;
	font-size: 0.8em;
	line-height: 1.4em;
}*/

.graph_line {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.graph_line_wrapper {
  position: absolute;
  bottom: -1.5vw;
  width: 100%;
}

.graph-straight,
.graph-curve {
  position: absolute;
  bottom: 0;
  left: 0;
}

.save_3_graph_straight {
  bottom: 24px;
}

/*.large_value{
	flex-grow: 3;
	padding-right: 60px;
}

.first_value{
	text-align: left;
}

.largest_value {
	text-align: right;
}*/

/* Graph Labels */

.graph_labels {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.y_axis_wrapper {
  display: flex;
  position: absolute;
  width: 9vw;
  height: 100%;
  right: -10vw;
  top: 0;
  flex-direction: column;
  justify-content: flex-start;
}

.start_value {
  position: absolute;
  bottom: -5px;
  left: 0;
  transform: translateX(-100%);
  height: 1.05em;
  width: auto;
  z-index: 200;
  padding-right: 10px;
  align-self: flex-start;
}

.start_value.invest_2_start,
.start_value.save_3_start,
.start_value.save_4_start {
  bottom: 3vw;
  left: -2.75vw;
  transform: translateX(0);
}

.end_value {
  z-index: 10;
  height: 1.05em;
  z-index: 200;
  width: auto;
  align-self: flex-start;
}

.end_wrapper,
.baseline_wrapper,
.midline_wrapper {
  display: flex;
  flex-direction: column;
  /*	flex-grow: 1;*/
  position: relative;
}

.end_wrapper {
  justify-content: flex-start;
  top: -5px;
}

.baseline_wrapper {
  justify-content: flex-end;
  bottom: -5px;
}

.midline_wrapper {
  /*	justify-content: center;*/
}

.graph_impact_wrapper {
  width: 145%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  /*	display: none;*/
  margin-left: -45%;
  padding-bottom: 5px;
}

.graph_impact {
  width: 100%;
  height: 100%;
  align-self: flex-start;
}

.graph_impact_wrapper p {
  font-size: 14px;
  line-height: 1.4em;
}

.save_1_start {
  bottom: -1px;
  /*	left: -5vw;*/
}

.save_1_end {
  position: absolute;
  bottom: -1px;
  right: 0vw;
  transform: translateX(100%);
  padding-left: 12px;
}

/*.save_2_start {
	bottom: 0vw;
	left: -5vw;
}*/

.graph_labels.save-3 .graph_impact_wrapper,
.graph_labels.save-4 .graph_impact_wrapper,
.graph_labels.invest-2 .graph_impact_wrapper,
.graph_labels.invest-3 .graph_impact_wrapper {
  flex-grow: 0;
  height: 34%;
}

.graph_labels.save-3 .midline_wrapper,
.graph_labels.save-4 .midline_wrapper,
.graph_labels.invest-2 .midline_wrapper,
.graph_labels.invest-3 .midline_wrapper {
  flex-grow: 3;
}

.graph_labels.save-3 .end_wrapper,
.graph_labels.save-4 .end-wrapper,
.graph_labels.invest-2 .end-wrapper,
.graph_labels.invest-3 .end-wrapper {
  flex-grow: 0;
}

.graph_labels.save-3 .midline_wrapper,
.graph_labels.save-4 .midline_wrapper,
.graph_labels.invest-2 .midline-wrapper,
.graph_labels.invest-3 .midline-wrapper {
  justify-content: flex-start;
}

/* Graph Nodes */

.graph_nodes {
  position: relative;
  height: 100%;
  z-index: 500;
}

.node {
  position: absolute;
}

.reverse_order {
  display: flex;
  flex-direction: column-reverse;
}

.node_content {
  display: none;
  width: 36vw;
  margin-left: -17vw;
  font-size: 0.9em;
  line-height: 1.4em;
  padding: 16px;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 2vw;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  position: relative;
}

.reverse_order .node_content {
  position: absolute;
  top: 32px;
}

.node_content--active {
  display: block;
}

.node_copy {
  margin-bottom: 0.5em;
  display: inline-block;
  color: #999;
  font-family: "FrutigerLTPro-Light", Courier, "Lucida Grande",
    "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
}

.node_link {
  margin-bottom: 0;
}

.node_trigger {
  display: block;
  width: 1.8vw;
  height: 1.8vw;
  background: #888;
  border-radius: 0.9vw;
  cursor: pointer;
  z-index: 40;
  position: relative;
}

.node_arrow {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 8px;
  margin-left: -10px;
}

.reverse_order .node_arrow {
  top: -16px;
  border-top-color: transparent;
  border-bottom-color: #fff;
}

.animation_circle.animation_active {
  animation: pulse 3s infinite;
  z-index: 10;
}

.animation_circle {
  background: #888;
  width: 1.8vw;
  height: 1.8vw;
  border-radius: 0.9vw;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  overflow: visible;
}

/* Sprite Graphics */

.sprite-logo {
  display: inline-block;
  text-indent: -9999999px;
  width: 115px;
  height: 42px;
  background: url(img/sprite-v2.png) no-repeat -0px -0px;
  background: url(img/sprite-v2.svg) no-repeat -0px -0px, none;
}

.sprite-close {
  float: right;
  display: inline-block;
  text-indent: -9999999px;
  width: 26px;
  height: 26px;
  background: url(img/sprite-v2.png) no-repeat -116px -0px;
  background: url(img/sprite-v2.svg) no-repeat -116px -0px, none;
}

.sprite-caret {
  display: inline-block;
  text-indent: -9999999px;
  width: 10px;
  height: 16px;
  background: url(img/sprite-v2.png) no-repeat -144px -0px;
  background: url(img/sprite-v2.svg) no-repeat -144px -0px, none;
  margin: 0 0 -3.5px 6px;
  transform: scale(0.75);
}

.sprite-ribbon {
  display: inline-block;
  text-indent: -9999999px;
  width: 280px;
  height: 314px;
  background: url(img/sprite-v2.png) no-repeat -0px -44px;
  background: url(img/sprite-v2.svg) no-repeat -0px -44px, none;
  bottom: 20px;
  left: 25px;
}

.sprite-start {
  display: inline-block;
  text-indent: -9999999px;
  width: 40px;
  height: 44px;
  background: url(img/sprite-v2.png) no-repeat -156px -0px;
  background: url(img/sprite-v2.svg) no-repeat -156px -0px, none;
  position: absolute;
  bottom: 0.5vw;
  left: 0.5vw;
  z-index: 100;
}

.sprite-chart {
  display: inline-block;
  text-indent: -9999999px;
  width: 150px;
  height: 118px;
  background: url(img/sprite-v2.png) no-repeat -280px -39px;
  background: url(img/sprite-v2.svg) no-repeat -280px -39px, none;
  margin-top: 18px;
}

.sprite-chat {
  display: inline-block;
  text-indent: -9999999px;
  width: 150px;
  height: 132px;
  background: url(img/sprite-v2.png) no-repeat -280px -162px;
  background: url(img/sprite-v2.svg) no-repeat -280px -162px, none;
}

.sprite-calendar {
  display: inline-block;
  text-indent: -9999999px;
  width: 100px;
  height: 112px;
  background: url(img/sprite-v2.png) no-repeat -280px -294px;
  background: url(img/sprite-v2.svg) no-repeat -280px -294px, none;
}

.sprite-phone {
  display: inline-block;
  text-indent: -9999999px;
  width: 100px;
  height: 86px;
  background: url(img/sprite-v2.png) no-repeat -380px -294px;
  background: url(img/sprite-v2.svg) no-repeat -380px -294px, none;
  margin: 16px 0 10px;
}

/* Graph Graphics - These are Responsive, Native Sizes included for Percentage Reference */

.graph-low {
  display: inline-block;
  width: 100%;
  height: 2.535211267605634vw;
  /*	max-height: 26px;*/
  /* width: 710px;
	height: 18px;*/
  background: url(img/graph-low-v2.png) no-repeat center center;
  background: url(img/graph-low-v2.svg) no-repeat center center, none;
  background-size: 100%;
  bottom: 1vw;
  overflow: visible;
}

.graph-mid {
  display: inline-block;
  width: 100%;
  height: 11.183098591549296vw;
  /*	max-height: 176px;*/
  /* width: 710px;
	height: 122px;*/
  background: url(img/graph-mid-v2.png) no-repeat center center;
  background: url(img/graph-mid-v2.svg) no-repeat center center, none;
  background-size: 100%;
  overflow: visible;
  bottom: 1.5vw;
}

.graph-high {
  display: inline-block;
  width: 100%;
  height: 24vw;
  /*	max-height: 338px;*/
  /*height: 32.957746478873239vw;*/
  /* width: 710px;
	height: 234px;*/
  background: url(img/graph-high-v2.png) no-repeat center center;
  background: url(img/graph-high-v2.svg) no-repeat center center, none;
  background-size: 100%;
  overflow: visible;
  bottom: 1.5vw;
}

.graph-straight {
  display: inline-block;
  width: 100%;
  height: 17.4418605vw;
  /*	max-height: 178px;*/
  /* width: 688px;
	height: 120px;*/
  background: url(img/graph-straight-v1.png) no-repeat center center;
  background: url(img/graph-straight-v1.svg) no-repeat center center, none;
  background-size: 100%;
  bottom: -1.5vw;
}

.graph-curve {
  display: inline-block;
  width: 100%;
  height: 16.4418605vw;
  /*	max-height: 178px;*/
  /* width: 688px;
	height: 120px;*/
  background: url(img/graph-curve-v2.png) no-repeat center center;
  background: url(img/graph-curve-v2.svg) no-repeat center center, none;
  background-size: 100%;
  bottom: -1.5vw;
}

/* Background Graphics - These are Responsive, Native Sizes included for Percentage Reference */

.background-image {
  width: 100%;
  /*	height: 34vw;*/
  padding-bottom: 0;
  margin-bottom: 24px;
  height: 32.5vw;
  /*max-height: 370px;*/
  /* width: 928px;
	height: 336px;*/
  background: url(img/background-image-v2.png) no-repeat center top;
  background: url(img/background-image-v2.svg) no-repeat center top, none;
  background-size: 100%;
  /*	margin-top: -3.2vw;*/
}

.mobile_animation {
  max-width: 100%;
}

/* Animations and Transitions */

/*.advance_button_wrapper .caret_wrapper {
	transition: all 0.3s ease;
	position: absolute;
	bottom: 0;
	right: 0px;
}*/

/*.advance_button_wrapper {
	padding-right: 15px;
}*/

/*.advance_button_wrapper:hover .caret_wrapper {
	right: -8px;
}
*/

.advance_button {
  padding-right: 17px;
  font-size: 1em;
  position: relative;
  float: right;
}

.advance_button::after {
  content: "";
  position: absolute;
  bottom: 4px;
  display: inline-block;
  width: 10px;
  height: 16px;
  background: url(img/sprite-v2.png) no-repeat -144px -0px;
  background: url(img/sprite-v2.png) no-repeat -144px -0px, none;
  transition: all 0.3s ease;
  transform: translate(75%, 0) scale(0.75);
}

.advance_button:hover::after {
  animation: bounce 0.25s infinite alternate;
}

.result_button_wrapper a {
  display: inline-block;
}

.result_button {
  font-size: 1em;
  position: relative;
}

.result_button .result_button_copy {
  padding-right: 0px;
  display: inline-block;
  width: 100%;
  position: relative;
}

.result_button .result_button_copy::after {
  content: "";
  position: absolute;
  bottom: 4px;
  display: inline-block;
  text-indent: -9999999px;
  width: 10px;
  height: 16px;
  background: url(img/sprite-v2.png) no-repeat -144px -0px;
  background: url(img/sprite-v2.png) no-repeat -144px -0px, none;
  transition: all 0.3s ease;
  transform: translate(75%, 0) scale(0.75);
}

.result_button:hover .result_button_copy::after {
  animation: bounce 0.25s infinite alternate;
}

.result_button .result_button_orphan {
  white-space: nowrap;
}

.contact_button .sprite-caret {
  margin: 0 0 -3.5px 6px;
  transform: scale(0.75);
  transition: all 0.3s ease;
}

.contact_button:hover .sprite-caret {
  animation: bounce-alt 0.25s infinite alternate;
}

.node_link {
  cursor: pointer;
}

.node_link .sprite-caret {
  margin: 0 0 -3.5px 6px;
  transform: scale(0.75);
  transition: all 0.3s ease;
}

.node_link:hover .sprite-caret {
  animation: bounce-alt 0.25s infinite alternate;
}

.disclaimer p,
.contact_disclaimer p {
  font-size: 12px !important;
  line-height: 20px !important;
}

/*.result_button_wrapper .sprite-caret{
	margin-left: 10px;
	transition: all 0.3s ease;
}

.result_button_wrapper:hover .sprite-caret{
	margin-left: 15px;
}*/

@media only screen and (min-width: 480px) {
  .display_size_fallback {
    display: none;
  }
  .screen_content {
    display: flex;
  }
}

@media only screen and (min-width: 992px) {
  body,
  button {
    /* font-size: 20px;
    line-height: 1.4em; */
  }

  /* .main_header {
    font-size: 4em;
  }
  .main_subheader {
    font-size: 2.4em;
    margin-bottom: 0.5em;
  }
  .header {
    font-size: 1.8em;
  } */
  .global_header {
    /*    padding: 0 8px 40px;*/
    width: 100%;
  }
  .screen {
    display: flex;
  }

  /* .start_button {
    padding: 4px 16px 0px 16px;
  } */
  .landing_copy_wrapper {
    max-width: 70%;
  }
  .landing_buttons_wrapper {
    margin-top: 24px;
  }
  .graph_impact_wrapper {
    /*		display: flex;*/
    padding-bottom: 10px;
    /*height: 37% !important;*/
    align-self: flex-start;
  }

  .graph_impact_wrapper.save_3_impact,
  .graph_impact_wrapper.save_4_impact {
    /*		display: block;*/
  }

  .graph_labels.save-3 .midline_wrapper,
  .graph_labels.save-4 .midline_wrapper,
  .graph_labels.invest-2 .midline_wrapper,
  .graph_labels.invest-3 .midline_wrapper {
    justify-content: flex-start;
  }

  .start_value {
    bottom: -10px;
  }

  .sprite-start {
    bottom: 1.25vw;
    left: 1vw;
  }

  .end_wrapper {
    top: -10px;
  }
  .baseline_wrapper {
    bottom: -10px;
  }

  .save_1_start {
    bottom: -2px;
  }

  /*	.start_value.invest_2_start,
	.start_value.save_3_start,
	.start_value.save_4_start  {
		bottom: 45px;
		left: -2.25vw;
	}
*/
  .save_1_end {
    bottom: -2px;
  }

  .node_content {
    width: 28vw;
    margin-left: -13vw;
  }
  .ribbon_wrapper {
    display: block;
  }

  .contact_button .sprite-caret {
    margin: 0 0 -1.5px 10px;
    transform: scale(1);
  }

  .node_link .sprite-caret {
    margin: 0 0 -1.5px 10px;
    transform: scale(1);
  }

  .advance_button {
    padding-right: 20px;
  }

  .advance_button::after {
    bottom: 8px;
    transform: translate(125%, 0) scale(1);
  }

  .result_button .result_button_copy::after {
    bottom: 8px;
    transform: translate(125%, 0) scale(1);
  }

  @keyframes bounce {
    0% {
      transform: translate(125%, 0) scale(1);
    }
    100% {
      transform: translate(200%, 0) scale(1);
    }
  }

  @keyframes bounce-alt {
    0% {
      margin: 0 0 -1.5px 10px;
      transform: scale(1);
    }
    100% {
      margin: 0 0 -1.5px 15px;
      transform: scale(1);
    }
  }

  .result_button_wrapper {
    max-width: 400px;
  }

  /* .disclaimer p,
  .legal_copy_wrapper p {
    font-size: 14px !important;
    line-height: 22px !important;
  } */

  .disclaimer {
    width: 50%;
  }

  .advance_button_content_wrapper {
    width: 50%;
  }
}

@media only screen and (min-width: 1280px) {
  body,
  button {
    /* font-size: 24px;
    line-height: 1.4em; */
  }

  .sprite-start {
    bottom: 1.5vw;
    transform: scale(1.5);
  }
}

/* Large Desktop with condensed height */
@media only screen and (max-height: 679px) and (max-width: 1279px) {
  html,
  body,
  #root {
    width: 100%;
    height: auto;
  }

  .graph_content {
    position: relative;
    bottom: auto;
    left: auto;
    margin-bottom: 20px;
  }
}

/* Large Desktop with condensed height */
@media only screen and (max-height: 689px) and (min-width: 1270px) and (max-width: 1399px) {
  html,
  body,
  #root {
    width: 100%;
    height: auto;
  }

  .graph_content {
    position: relative;
    bottom: auto;
    left: auto;
    margin-bottom: 20px;
  }
}

/* Large Desktop with condensed height */
@media only screen and (max-height: 719px) and (min-width: 1440px) {
  html,
  body,
  #root {
    width: 100%;
    height: auto;
  }

  .graph_content {
    position: relative;
    bottom: auto;
    left: auto;
    margin-bottom: 20px;
  }
}

/* Phone Landscape  */
@media only screen and (min-width: 480px) and (max-width: 767px) {
  html,
  body,
  #root {
    width: 100%;
    height: auto;
  }

  .graph_content {
    position: relative;
    bottom: auto;
    left: auto;
    margin-bottom: 20px;
  }
}
